<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New App
      </app-btn>
    </v-col>
    <material-card icon="mdi-account-group" icon-small color="primary" :title="selectedCompanyName + ' Apps'">
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" class="ml-auto" hide-details label="Search records"
          single-line style="max-width: 250px" />

        <v-divider class="mt-3" />

        <data-table-wrapper ref="dataTable" :headers="headers" :items.sync="items" server-side-rendering
          @load="loadData">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1350px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <div class="d-flex align-center justify-center ma-1 mb-1"
                      style="padding: 15px 20px; border-radius: 5px; background: #f5f5f5;">
                      <v-autocomplete v-model="referenceId" label="Reference" :items="companies" item-text="pathStr"
                        item-value="id" hide-details @change="chooseReference" />
                    </div>
                    <div class="d-flex align-center justify-center ma-1 mb-1"
                      style="padding: 15px 20px; border-radius: 5px; background: #f5f5f5;">
                      <v-row>
                        <v-col cols="4"> <v-text-field v-model="editedItem.title" label="Title" hide-details /></v-col>
                        <v-col cols="4"> <v-text-field v-model="editedItem.label" label="Label" hide-details /></v-col>
                        <v-col cols="4"> <v-text-field v-model="editedItem.type" label="Type" hide-details /></v-col>
                        <v-col cols="6"> <v-text-field v-model="editedItem.packageName" label="Package Name"
                            hide-details /></v-col>
                        <v-col cols="6"> <v-text-field v-model="editedItem.activity" label="Activity"
                            hide-details /></v-col>
                        <!-- <v-col cols="3"> <v-text-field v-model="editedItem.folderId" label="Folder Id"
                            hide-details /></v-col>
                        <v-col cols="3"><v-text-field v-model="editedItem.folderOrder" label="Folder Order"
                            hide-details /></v-col> -->
                        <v-col cols="6">
                          <dropzone @drop="onFileDrop" />
                        </v-col>
                        <v-col cols="2"><v-switch v-model="editedItem.autoLaunch" label="Auto Launch"
                            hide-details /></v-col>
                        <v-col cols="2"><v-switch v-model="editedItem.vpnConnect" label="Vpn Connect"
                            hide-details /></v-col>
                        <v-col cols="2"><v-switch v-model="editedItem.visible" label="Visible" hide-details /></v-col>

                        <v-col>
                          <app-btn @click="addNewApp">Save</app-btn>
                        </v-col>
                      </v-row>

                    </div>
                    <editable-table :headers="headers" :items="editedItems">
                      <template v-slot:[`item.icon`]="{ item }">
                        <img :src="item.iconBase64" class="pa-2" width="50" height="50" style="object-fit: cover;" />
                      </template>
                    </editable-table>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`item.icon`]="{ item }">
            <img :src="$resourceUrl('/' + item.icon)" class="pa-2" width="50" height="50" style="object-fit: cover;" />
          </template>
        </data-table-wrapper>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import { get } from 'vuex-pathify'
import store from '../store'
import { TYPES } from './components/custom/types'

export default {
  name: 'DataTablesView',
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      length15: (value) => value.length <= 15 || 'Maximum 15 characters.',
    },
    dialog: false,
    headers: [
      { text: 'Icon', value: 'icon' },
      { text: 'Title', value: 'title' },
      { text: 'Label', value: 'label' },
      { text: 'Type', value: 'type' },
      { text: 'AutoLaunch', value: 'autoLaunch' },
      { text: 'VpnConnect', value: 'vpnConnect' },
      { text: 'Visible', value: 'visible' },
      { text: 'PackageName', value: 'packageName' },
      { text: 'Activity', value: 'activity' },
    ],
    items: [],
    editedItem: {
      icon: '',
      iconBase64: '',
      title: '',
      label: '',
      type: "",
      autoLaunch: false,
      vpnConnect: false,
      visible: false,
      packageName: '',
      activity: ''
    },
    defaultItem: {
      icon: '',
      iconBase64: '',
      title: '',
      label: '',
      type: "",
      autoLaunch: false,
      vpnConnect: false,
      visible: false,
      packageName: '',
      activity: ''
    },
    editedIndex: -1,
    editedItems: [],
    search: undefined,
    referenceId: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Add New App' : 'Edit App'
    },
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    companies() {
      return store.getters['company/getCompanies']
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    selectedCompanyId() {
      this.$refs.dataTable.reload()
    },
  },

  methods: {
    loadData({ page, itemsPerPage, search, callback }, companyId) {
      companyId ??= this.selectedCompanyId
      this.getCompanyApps({ page, itemsPerPage, search }, companyId).then(res => {
        callback(res.data.data.items, res.data.data.totalCount)
      })
    },
    getCompanyApps({ page, itemsPerPage, search }, companyId) {
      return this.$http.post(`/CompanyKioskApp/paginated/${companyId}`, {
        "pageNumber": page,
        "pageSize": itemsPerPage,
        search
      })
    },
    close() {
      this.editedItems = []
      this.dialog = false
    },

    save() {
      if (!this.editedItems || !this.editedItems.length) {
        return
      }

      if (this.selectedCompanyId === 0) {
        this.$toast.error('Company not found.', 'Error', {
          position: 'topRight',
        })
        return
      }

      const formData = new FormData()

      this.editedItems.forEach((app, index) => {
        formData.append(`KioskApps[${index}].type`, app.type || '');
        formData.append(`KioskApps[${index}].autoLaunch`, !!app.autoLaunch);
        formData.append(`KioskApps[${index}].vpnConnect`, !!app.vpnConnect);
        formData.append(`KioskApps[${index}].visible`, !!app.visible);
        formData.append(`KioskApps[${index}].label`, app.label || '');
        formData.append(`KioskApps[${index}].activity`, app.activity || '');
        formData.append(`KioskApps[${index}].folderId`, app.folderId || 0);
        formData.append(`KioskApps[${index}].title`, app.title || '');
        formData.append(`KioskApps[${index}].folderOrder`, app.folderOrder || 0);
        formData.append(`KioskApps[${index}].packageName`, app.packageName || '');
        formData.append(`KioskApps[${index}].icon`, app.icon);
      });

      this.$http.post(`/CompanyKioskApp/${this.selectedCompanyId}`, formData).then((response) => {
        if (response.data.success) {
          this.$toast.success('Apps added successfully.', 'Success', {
            position: 'topRight',
          })
          this.items.unshift(...response.data.data)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })

      this.close()
    },
    addNewApp() {
      this.editedItems.unshift(this.editedItem)
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    update(item) {
      if (!item.configurationId && item.configurationId === 0) {
        return
      }

      this.$http.put(`/DeviceConfiguration/${item.configurationId}`, item).then(response => {
        if (response.data.success) {
          this.$toast.success('Config updated successfully.', 'Success', {
            position: 'topRight',
          })
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },
    remove(item) {
      this.$http.delete(`/CompanyConfiguration/DeleteCompanyConfiguration?id=${item.id}`).then(response => {
        if (response.data.success) {
          this.$toast.success('Config removed successfully.', 'Success', {
            position: 'topRight',
          })

          const index = this.items.indexOf(item)
          this.items.splice(index, 1)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },

    chooseReference() {
      this.editedItems = []

      if (!this.referenceId || this.referenceId === 0) {
        return
      }

      this.getCompanyApps({ page: 1, itemsPerPage: 50 }, this.referenceId).then(async (res) => {
        const items = res.data?.data?.items || []
        if (items) {
          const _items = items.map(async (x) => {
            const url = this.$resourceUrl('/' + x.icon)
            const filename = x.icon?.split('/')?.slice(-1)?.[0] || ''
            const filenameWithoutGuid = filename?.split('_')?.slice(1)?.join('_') || ''
            const blob = await this.getImageBlob(url)
            console.log();

            x.iconBase64 = await this.blobToBase64(blob)
            x.icon = new File([blob], filenameWithoutGuid)
            return x;
          })

          this.editedItems = await Promise.all(_items)
        }
      })
    },

    async getImageBlob(url) {
      const response = await this.$http(url, { responseType: 'blob' })
      return response.data
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async onFileDrop(file) {
      this.editedItem.icon = file
      this.editedItem.iconBase64 = await this.blobToBase64(file);
    }
  },
}
</script>
